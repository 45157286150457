import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ifProp, prop, theme } from 'styled-tools';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import BaseDrawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import { useQuery } from '@apollo/client';
import LiveVideoPlayer from 'components/VideoPlayer/LiveVideoPlayer';
import OptionsDisclaimer from 'components/OptionsDisclaimer';
import VideoControls from './VideoControls';
import AudioPlayer from 'components/AudioPlayer';
import LivePlayerSidebar from './LivePlayerSidebar';
import { useLivePlayerContext } from 'context/LivePlayerContext';
import { useSiteSettings } from 'context/SiteSettingsContext';
import { getEpisodeVideoId } from 'utils/episodeHelpers';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { autoOptimizeImage } from 'utils/functions';
import { useAuth } from 'context/AuthContext';
import getLiveStreamPosterImage from 'graphql/queries/getLiveStreamPosterImage.graphql';
import pageShape from 'shapes/pageShape';
import livePlayerSidebarShape from 'shapes/livePlayerSidebarShape';
import ScheduleTradeNews from './ScheduleTradeNews';
import PropTypes from 'prop-types';
import { useVideoPlayerContext } from 'context/VideoPlayerContext';


const LiveVideoWrapper = styled(Container)`
  margin: auto;
  max-width: min(100%, calc(175vh - 30%));

  ${props => props.theme.breakpoints.up('md')} {
    padding-left: 0;
    padding-right: 0;
  }

  ${props => props.theme.breakpoints.up('lg')} {
    @media (min-width: 1440px) {
    width: 88%;
    max-width: calc(175vh - 17%);
  }

  ${props => props.theme.breakpoints.up('xl')} {
    max-width: calc(175vh - 13%);
  }
`;

const LiveVideoSection = styled.div`
  background-color: ${theme('colors.videoBackground')};
  background-image: ${({ $background }) => ($background ? `url(${$background})` : 'none')};
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;

  ${props => props.theme.breakpoints.up('sm')} {
    margin-bottom: 1rem;
  }
`;

const VideoOverLay = styled.div`
  position: absolute;
  top: 6rem;
  height: ${({$display}) => ($display ? '0' : '88vh')};
  width: 100%;
  background-color: transparent;
  z-index: 1100;
  cursor: pointer;
  
  ${props => props.theme.breakpoints.down('sm')} {
    height: ${({$display}) => ($display ? '0' : '60vh')};
  }

  ${props => props.theme.breakpoints.down('xs')} {
    height: ${({$display}) => ($display ? '0' : '25rem')};
  }
`;

const SidebarGridItem = styled(Grid)`
  min-width: 320px;

  ${ifProp('$maxHeight', css`
    max-height: ${prop('$maxHeight')}px;
    overflow: hidden;
  `)}

  ${props => props.theme.breakpoints.up('md')} {
    min-width: 320px;
  }
`;

const VideoPlayerContent = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .video-js {
    .vjs-dock-text {
      display: none;
    }

    .vjs-big-play-button {
      background-color: rgba(255, 255, 255, 0.45);
      color: ${theme('colors.black')};

      &:hover {
        background-color: ${theme('colors.white')};
      }
    }
    
    .vjs-control-bar .vjs-menu-content{
      width: 10rem;
    }
  }
`;

const VideoPlayerGridItem = styled(Grid)`
  position: relative;
  padding-top: calc(56.25% + 41px);

  @media (max-width: 378px) {
    padding-top: calc(56.25% + 53px);
  }

  @media (min-width: 737px) {
    padding-top: calc(56.25% + 29px);
  }

  ${props => props.theme.breakpoints.up('md')} {
    max-width: 100%;
    padding-top: 56.25%;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    grid-column: 1 / span 2;
  }
`;

const AudioPlayerGridItem = styled(Grid)`
  position: relative;
  padding-top: 239px;

  ${props => props.theme.breakpoints.up('md')} {
    max-width: 100%;
    padding-top: 439px;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    grid-column: 1 / span 2;
  }
`;

const LiveAudioContainerWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;

  ${props => props.theme.breakpoints.up('md')} {
    width: 100%;
  }
`;

const Drawer = styled(BaseDrawer)`
  // if tablet, only open drawer 50%
  ${props => props.theme.breakpoints.up('sm')} {
    .MuiDrawer-paper {
      width: 30%;
      min-width: 320px;
    }
  }

  ${props => props.theme.breakpoints.down('xs')} {
    .MuiDrawer-paper {
      width: 100%;
      min-width: 320px;
    }
  }
`;

const AudioPlayerWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
`;

const LiveAudioContainer = styled.div`
  position: relative;
  background-color: ${theme('colors.black')};
  background-image: url('${props => props.backgroundImage}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 18.75rem;
  min-height: 27.4375rem;
  height: 100%;

  ${props => props.theme.breakpoints.down('md')} {
    min-height: 14.9375rem;
    background-position-y: 0.75rem;
    background-size: 16.75rem;
  }
`;

LoggedInLiveVideo.propTypes = {
  page: pageShape,
  data: livePlayerSidebarShape,
  background: PropTypes.string,
};

function LoggedInLiveVideo({ page, data, background , setShowVideoPlayer, showVideoPlayer }) {
  const theme = useTheme();
  const videoRef = useRef();
  const { state: { coverClicked } } = useVideoPlayerContext();
  const [columnHeight, setColumnHeight] = useState(null);
  const {
    state: { isDrawerOpen, selectedEpisode, isVideoMode },
    closeMobileDrawer,
  } = useLivePlayerContext();
  const { siteSettings } = useSiteSettings();
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState();
  const [hasStarted, setHasStarted] = useState(null)
  const [autoplay, setAutoplay] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { user } = useAuth();

  useEffect(() => {
    if (videoRef.current && playerLoaded) {
      setColumnHeight(videoRef.current.offsetHeight);
    }

    function handleResize() {
      if (videoRef.current && playerLoaded) {
        setColumnHeight(videoRef.current.offsetHeight);
      }
    }

    window.addEventListener('resize', handleResize);
  }, [videoRef.current, playerLoaded]);

  useEffect(() => {
    if ((user || coverClicked) && videoPlayer) {
      setAutoplay('any')
      startVideoPlayer();
    }
  }, [user, videoPlayer]);

  const onVideoPlayerLoaded = success => {
    setPlayerLoaded(true);
    const myPlayer = success.ref;
    setVideoPlayer(myPlayer);

    myPlayer.on('play', function() {
      setHasStarted(true)
    })
  };

  const startVideoPlayer = () => {
    if(videoPlayer) {
      setShowVideoPlayer(true)
      setTimeout(() => {
        videoPlayer.play()
      }, 400)
    }
  }

  // If an episode is selected from the live schedule we play it in the live player instead of live feed.
  const episodeVideoId = selectedEpisode && getEpisodeVideoId(selectedEpisode);

  const disclaimer = (
    <OptionsDisclaimer
      intro='Market Data provided by CME Group & powered by dxFeed Technology. Options involve risk and are not
      suitable for all investors.'
      placement='homepage'
    />
  );
  return (
    <LiveVideoSection data-tour='mutable-element' id='live-video-container' $background={autoOptimizeImage(background)}>
      <VideoOverLay $display={showVideoPlayer} onClick={startVideoPlayer}/>
      <ScheduleTradeNews data={data}/>
      <LiveVideoWrapper maxWidth='none' disableGutters>
        {isVideoMode ? (
          <VideoPlayerGridItem item xs={12} md={9} ref={videoRef}>
            <VideoPlayerContent>
              <LiveVideoPlayer
                autoplay={autoplay}
                onSuccess={onVideoPlayerLoaded}
                videoId={episodeVideoId}
                live={!episodeVideoId}
              />
              {!isDesktop && disclaimer}
            </VideoPlayerContent>
          </VideoPlayerGridItem>
        ) : (
            <AudioPlayerGridItem>
              <LiveAudioContainerWrapper>
                <LiveAudioContainer
                  backgroundImage={autoOptimizeImage(
                    siteSettings?.livePlayerSettings?.liveAudioImage?.url
                  )}
                >
                  <AudioPlayerWrapper>
                    <AudioPlayer
                      src={siteSettings?.livePlayerSettings?.secureLiveAudioUrl}
                      autoplay={true}
                    />
                    {!isDesktop && disclaimer}
                  </AudioPlayerWrapper>
                </LiveAudioContainer>
              </LiveAudioContainerWrapper>
            </AudioPlayerGridItem>
          )}
          <Hidden smDown>
            <SidebarGridItem item $maxHeight={columnHeight}>
              {playerLoaded && <LivePlayerSidebar />}
            </SidebarGridItem>
          </Hidden>

          {/* Drawer with sidebar content - only in mobile */}
          <Hidden mdUp>
            <Drawer
              anchor='right'
              open={isDrawerOpen}
              onClose={closeMobileDrawer}
            >
              <LivePlayerSidebar />
            </Drawer>
          </Hidden>
        <VideoControls disclaimer={isDesktop && disclaimer} />
      </LiveVideoWrapper>
    </LiveVideoSection>
  );
}

export default React.memo(LoggedInLiveVideo);
